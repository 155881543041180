<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.ID }}</td>
                            <td>{{ item.Username }}</td>
                            <td class="text-end">{{ item.Amount | currency}}</td>
                            <td>{{ item.Note }}</td>
                            <td v-bind:style="{ 'color': get_type_color(item.Status, withdraw_status_list)}">{{ item.Status | display_value(withdraw_status_list) }}</td>
                            <td>{{ item.CreatedDate | display_date }}</td>
                            <td>{{ item.CreatedBy }}</td>
                            <td class="text-center">
                                <v-icon medium class="mr-2" @click="gotoDetail(item.ID)">fa-external-link-alt</v-icon>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:body.append v-if="summary">
                        <tr class="total-summary-td">
                            <td colspan="2" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.Amount | currency}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
        <v-dialog v-model="dialogWithDraw" persistent max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="title">Lệnh rút tiền {{currentWithDraw.ID > 0 ? '' : '(Tạo mới)'}}</span>
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap pa-3>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-autocomplete :disabled="currentWithDraw.ID > 0" label="Khách hàng" v-model="currentWithDraw.UID" :items="customers" :item-text="getUserName" item-value="ID" :rules="requireRules"></v-autocomplete>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-select :disabled="(currentWithDraw.ID > 0 && currentWithDraw.Status !== withdraw_status.Pending) || !roleEditable" v-model="currentWithDraw.Source" :items="moneySources" item-text="name" item-value="value" label="Chọn nguồn" :rules="requireRules"></v-select>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <h-currency-input :disabled="currentWithDraw.ID > 0" v-model="currentWithDraw.Amount" :decimal="0" label="Số tiền" class="input-group--focused"></h-currency-input>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 v-if="currentWithDraw.ID > 0">
                            <v-text-field disabled label="Tên ngân hàng" v-model="currentWithDraw.Bank" class="input-group--focused"></v-text-field>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 v-if="currentWithDraw.ID > 0">
                            <v-text-field disabled label="Chủ tài khoản" v-model="currentWithDraw.BankAccountName" class="input-group--focused"></v-text-field>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 v-if="currentWithDraw.ID > 0">
                            <v-text-field disabled label="Số tài khoản" v-model="currentWithDraw.BankAccountNumber" class="input-group--focused"></v-text-field>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-textarea :disabled="currentWithDraw.ID > 0" label="Ghi chú" v-model="currentWithDraw.Note" class="input-group--focused"></v-textarea>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-select v-if="currentWithDraw.ID > 0" :disabled="currentWithDraw.Status !== withdraw_status.Pending || !roleEditable" v-model="editStatus" :items="withdraw_status_list" item-text="name" item-value="value"></v-select>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogWithDraw = false">Đóng</v-btn>
                    <v-btn color="blue darken-1" text @click="preSaveData" :disabled="currentWithDraw.Status !== 1 && currentWithDraw.ID > 0">Lưu lại</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import { request_list } from '@/commons/hpod';
import Vue from 'vue';
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            headers: [{
                    text: "ID",
                    value: "ID",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "User name",
                    value: "Username",
                    filterable: true,
                    quickSearch: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Số tiền",
                    value: "Amount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                 {
                    text: "Ghi chú",
                    value: "Note",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    dataType: dataType["Enum"],
                    values: configs.WITHDRAW_STATUS_LIST
                },
                {
                    text: "Ngày tạo",
                    value: "CreatedDate",
                    filterable: true,
                    dataType: dataType["Date"]
                },
                {
                    text: "Người tạo",
                    value: "CreatedBy",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
            withdraw_status: configs.WITHDRAW_STATUS,
            withdraw_status_list: configs.WITHDRAW_STATUS_LIST,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "withdraw_list",
            filterConditions: [],
            quickFilterConditions: [],
            dialogWithDraw: false,
            editStatus: 1,
            moneySources: [..._.filter(configs.MONEY_SOURCES, (c)=>{return c.value < 90;}), { value: 89, name: 'Khác'}],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ?
                    filterConditions[this.viewName] : [];
                this.filter_data();
            },
            deep: true
        },
        currentWithDraw() {
            this.editStatus = this.currentWithDraw.Status;
        },
        roleEditable() {
            this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !this.roleEditable});
        },
        items() {
            if(this.widhdrawId > 0) {
                this.gotoDetail(this.widhdrawId);
                this.$router.replace({'query': null});
            }
        }
    },
    computed: {
        ...mapState({
            items: state => state.withDraw.all.data,
            total_rows: state => state.withDraw.all.total,
            loading: state => state.withDraw.loading,
            summary: state => state.withDraw.all.summary,
            currentWithDraw: state => state.withDraw.selected,
            customers: state => state.customer.master.data,
        }),
        roleEditable() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin || (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
        widhdrawId() {
            return this.$route.query.id && this.$route.query.id != '' ? Number(this.$route.query.id) : 0;
        },
    },
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("withDraw/getList", param);
            this.getCustomerList();
        },
        gotoDetail: function (id) {
            this.$store.dispatch("withDraw/getDetail", id);
            setTimeout(this.dialogWithDraw = true, 200);
        },
        preSaveData() {
            window.getApp.$emit('PRE_SAVE_DATA');
        },
        saveData() {
            if(this.currentWithDraw.Amount <= 0 || !this.currentWithDraw.UID) {
                window.getApp.$emit('APP_RESOURCE_FAILED', `Hãy nhập số tiền và user muốn rút`);
                return;
            }
            // create from admin site
            if(this.currentWithDraw.ID == 0) {
                let customer = _.find(this.customers, {
                    ID: this.currentWithDraw.UID
                });
                if (customer) {
                    //if(this.editStatus == this.withdraw_status.Approved && customer.Wallet < this.currentWithDraw.Amount) {
                    //    window.getApp.$emit('APP_RESOURCE_FAILED', `Tài khoản không đủ để thực hiện lệnh rút`);
                    //    return;
                    //}
                    this.currentWithDraw.Username = customer.Username;
                    this.currentWithDraw.Status = this.editStatus;
                    this.$store.dispatch("withDraw/setDetail", {
                        data: this.currentWithDraw,
                        id: this.currentWithDraw.ID
                    });
                    this.dialogWithDraw = false;
                    window.getApp.$emit('APP_RESOURCE_UPDATED', `Thêm lệnh rút tiền thành công.`);
                    setTimeout(this.filter_data, 100);
                }
                else { 
                    window.getApp.$emit('APP_RESOURCE_FAILED', 'Không tìm thấy khách hàng.');
                }
            }
            else {
                this.currentWithDraw.Status = this.editStatus;
                this.$store.dispatch("withDraw/setDetail", {
                    data: this.currentWithDraw,
                    id: this.currentWithDraw.ID
                });
                this.dialogWithDraw = false;
                window.getApp.$emit('APP_RESOURCE_UPDATED', `Cập nhật lệnh rút tiền thành công.`);
                setTimeout(this.filter_data, 100);
            }
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy;
            let sortDesc = this.pagination.sortDesc;
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        createData(username = '') {
            this.$store.commit("withDraw/setDetail", {
                data: {
                    ID: 0,
                    UID: 0,
                    Username: username,
                    Amount: 0,
                    Status: this.roleEditable ? this.withdraw_status.Approved : this.withdraw_status.Pending,
                    Note: ''
                }
            })
            this.dialogWithDraw = true;
        },
        get_type_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username", "CustomerCode", "Wallet"]
            });
        },
        getUserName(item) {
            return `${item.Username} - ${item.CustomerCode}`;
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !this.roleEditable});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("withDraw/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
        if(this.$route.query.username && this.$route.query.username !== '') {
            this.createData(this.$route.query.username)
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
